import React,{Component} from 'react';
import { withRouter } from 'react-router-dom'
import './Toolbar.css';
class Toolbar extends Component{
  render(){
      return (
        <div className="Toolbar">
          <div className="Toolbar-Actual">
            <div className="cntr">
              <div className="cntr-innr">
                <label className="search" htmlFor="inpt_search">
                  <input id="inpt_search" type="text" />
                </label>
              </div>
            </div>
            <div onClick={this.props.history.goBack} className="bk-btn"><div className="bk-btn-triangle"></div><div className="bk-btn-bar"></div></div>
          </div>
        </div>
      )
  }
}
export default withRouter(Toolbar);