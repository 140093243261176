import React,{useContext}             from 'react';
import {GlobalProvider,GlobalContext} from './GlobalState';
export const AppLoader = (params) => {
const context =  useContext(GlobalContext);
const loadPosts=()=>{
    fetch("http://the10wishes.herokuapp.com/api/v1/bucketlist/",{'headers':{'authorization':'Bearer '+context.auth.token}})
      .then(res => res.json())
      .then(
        (result) => {
            context.loadPosts(result['data']);
        },
        (error) => {
          console.log('Errore nel caricamento dei post')
        }
      )
  }
console.log('secondo voi ho caricato?');
console.log(context.loaded);
if(!context.loaded){
  context.setPostLoaded(true);
  //params['app'].setState({'token':context.auth.token});
  loadPosts();
}
return (<div></div>);
}