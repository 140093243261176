import React,{createContext,useReducer} from 'react';
import AppReducer from  './AppReducer';
import uuid              from 'uuid';
import { useHistory } from "react-router-dom";
const initialState = {
	loaded:false,
	home:'/projects/websites/bucket_list',
	is_loading:false,
	cookies:{},
	auth:{},
	posts:[
	],
	feed:[
	    /*{'id':1, _id:1, 'checked':true,  'published':true, 'title':'Trip to australia','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/qaNcz43MeY8','dateUpdated':new Date(),'dateCreated':new Date()},
		{'id':2, _id:2, 'checked':false, 'published':true, 'title':'Rent a Lambo','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/1_lxISUE3F4','dateUpdated':new Date(),'dateCreated':new Date()},
		{'id':3, _id:3, 'checked':true,  'published':true, 'title':'Plant trees','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/sMQiL_2v4vs','dateUpdated':new Date(),'dateCreated':new Date()},
		{'id':4, _id:4, 'checked':true,  'published':true, 'title':'Hold a cat','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/LEpfefQf4rU','dateUpdated':new Date(),'dateCreated':new Date()},
		{'id':5, _id:5,'checked':true,   'published':true, 'title':'Attend a rodeo','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/nGZsjDn_s6U','dateUpdated':new Date(),'dateCreated':new Date()}
	*/],
	loadedPosts:[
	],
	userQuery:'',
	userSearch:{},
	pending_loaded:false,
	friends_loaded:false,
	feed_loaded:false,
	friends:{},
	friendsRequests:{},
	title:'Vorrei tanto...',
	loadedUsers:{},
	me:{}
};
//Create Context
export const GlobalContext = createContext(initialState);
//Provider Component
export const GlobalProvider = ({ children }) => {
	const [state, dispatch] = useReducer(AppReducer,initialState);
	var cookies = {}; 
	document.cookie.split(';').map(function(x){ 
	    var s=x.split(/=(.+)/);  
	    var key   =(typeof s[0]!=='undefined')?s[0].trim():''; 
	    var value =(typeof s[1]!=='undefined')?s[1].trim():'';  
	    cookies[key]=value;
	  });
	state.cookies = cookies;
	if(typeof cookies['token']!='undefined')
		state.auth['token']=cookies['token'];
	if(typeof cookies['me']!='undefined'){
		state.me=JSON.parse(cookies['me']);
		state.loadedUsers[state.me._id] = state.me;
	}
    let history = useHistory();
    function get(obj, key) {
    	return key.split(".").reduce(function(o, x) {
	        return (typeof o == "undefined" || o === null) ? o : o[x];
	    }, obj);
    }
    function getCommentsByPostId(postId){
 		if(typeof state.loadedPosts[postId]!='undefined' && typeof state.loadedPosts[postId]['comments']!='undefined')
 			return state.loadedPosts[postId]['comments'];
 		else
    		return null;
    }
    function isLogged(){
    	var token = get(state,'auth.token'); 
    	return (typeof token!='undefined' && token);
    }
	function deletePost(id){
		var xhr = formatXhr('DELETE', 'http://the10wishes.herokuapp.com/api/v1/bucketlist/'+id);		
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.send();
		dispatch({
			type:"DELETE_POST",
			payload:id
		});
	}
	function archivePost(id){
		var post = findById(id);
		var published = !post.published;
		var xhr = formatXhr('POST', 'http://the10wishes.herokuapp.com/api/v1/bucketlist/'+id);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.send(JSON.stringify({published:published}));
		dispatch({
			type:"ARCHIVE_POST",
			payload:id
		});
	}
	function findById(id){
		if(typeof state.loadedPosts[id]!='undefined')
 			return state.loadedPosts[id];
 		else
    		return null;
	}
	function checkPost(id){
		var post = findById(id);
		var checked = !post.checked;
		var xhr = formatXhr('POST', 'http://the10wishes.herokuapp.com/api/v1/bucketlist/'+id);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.send(JSON.stringify({checked:checked}));
		dispatch({
			type:"CHECK_POST",
			payload:id
		});
	}
	function fetchUser(userid){
		if(typeof state.loadedUsers[userid]=='undefined'){
			state.loadedUsers[userid]={};
			loading(true);
			fetch('http://the10wishes.herokuapp.com/api/v1/users/basic-info/'+userid,{'headers':{'authorization':'Bearer '+state.auth.token}})
			.then(res=>res.json())
			.then((result)=>{
				if(typeof result['data']!='undefined'){
					dispatch({
							type:"USER_INFO_FETCHED",
							payload:result['data']
						});
				}			
			    loading(false);
			})		
		}		
	}
	function searchUser(search){
		if(typeof state.userSearch[search]=='undefined' && search.length>2){
			fetch('http://the10wishes.herokuapp.com/api/v1/users/find/'+search,{'headers':{'authorization':'Bearer '+state.auth.token}})
		      .then(res => res.json())
		      .then(
		        (result) => {
		            dispatch({
						type:"SEARCH_USER_COMPLETED",
						payload:{'result':result['data'],search}
					});
		            loading(false);
		        },
		        (error) => {
		          loading(false);
		        }
		      )
		}else{
			if(typeof state.userSearch[search]!='undefined'){
			 dispatch({
						type:"SEARCH_USER_COMPLETED",
						payload:{'result':state.userSearch[search],search}
					});
			}
		}		
	}
	function befriend(id){
		fetch('http://the10wishes.herokuapp.com/api/v1/users/befriend/'+id,{'headers':{'authorization':'Bearer '+state.auth.token}}) 
			.then(res => res.json())
		    .then(
		        (result) => {
		            dispatch({
						type:"FRIEND_BEFRIENDED",
						payload:result['data']
					});
		            loading(false);
		        },
		        (error) => {
		          loading(false);
		        }
		      );
	}
	function loadFriends(){
		if(!state.friends_loaded){
			loading(true);
			fetch('http://the10wishes.herokuapp.com/api/v1/users/friends',{'headers':{'authorization':'Bearer '+state.auth.token}})
		     .then(res => res.json())
		     .then(
		        (result) => {
		            dispatch({
						type:"FRIENDS_FETCHED",
						payload:result['data']
					});
		            loading(false);
		        },
		        (error) => {
		          loading(false);
		        }
		      );
		}
		if(!state.pending_loaded){
			loading(true);
			fetch('http://the10wishes.herokuapp.com/api/v1/users/pending',{'headers':{'authorization':'Bearer '+state.auth.token}})
		    .then(res => res.json())
		    .then(
		        (result) => {
		            dispatch({
						type:"PENDING_REQUESTS_FETCHED",
						payload:result['data']
					});
		            loading(false);
		        },
		        (error) => {
		          loading(false);
		        }
		      );
		}
	}
	function getFeed(){
	if(!state.feed_loaded){
		setFeedLoaded(true);
		 loading(true);
		  fetch("http://the10wishes.herokuapp.com/api/v1/bucketlist/feed",{'headers':{'authorization':'Bearer '+state.auth.token}})
	      .then(res => res.json())
	      .then(
	        (result) => {
	            loadFeed(result['data']);
	            state.feed_loaded = true;
	            loading(false);
	        },
	        (error) => {
	           loading(false);
	          console.log('Errore nel caricamento dei post')
	        }
	      )
	}
	}
	function addPost(post,formData){
		loading(true);
		console.log(formData);
		post._id = uuid.v4();
		post.id = post._id;
		post.checked = false;
		post.published = true;
		post.dateUpdated = new Date();
		post.dateCreated = new Date();
		formData.append('_id',post._id );
		formData.append('id',post.id );
		formData.append('checked',post.checked );
		formData.append('published',post.published );
		formData.append('dateUpdated',post.dateUpdated );
		formData.append('dateCreated',post.dateCreated );
		var xhr = formatXhr('POST', 'http://the10wishes.herokuapp.com/api/v1/bucketlist/');
		xhr.send(formData);
		setTimeout(function() {
			dispatch({
				type:"ADD_POST",
				payload:post
			});
			loading(false);
		}, 2000);
	}
	function addComment(comment){
		loading(true);
		comment._id = uuid.v4();
		comment.id = comment._id;
		comment.user = {'_id':state.me._id};
		comment.dateUpdated = new Date();
		comment.dateCreated = new Date();
		var xhr = formatXhr('POST', 'http://the10wishes.herokuapp.com/api/v1/comments/');
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onload = function(){
			console.log('uela');
			dispatch({
				type:"ADD_COMMENT",
				payload:comment
			});
			loading(false);
		};
		xhr.send(JSON.stringify(comment));
	}
	function addCommentToFriendFeed(comment){
		loading(true);
		comment._id = uuid.v4();
		comment.id = comment._id;
		comment.dateUpdated = new Date();
		comment.dateCreated = new Date();
		var xhr = formatXhr('POST', 'http://the10wishes.herokuapp.com/api/v1/comments/');
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onload = function(){
			console.log('uela');
			dispatch({
				type:"ADD_COMMENT",
				payload:comment
			});
			loading(false);
		};
		xhr.send(JSON.stringify(comment));
	}
	function fetchComments(postId){
		const post = findById(postId);
		console.log('fetch: ');
		console.log(post);
		if(post && (typeof post.loaded=='undefined' || !post.loaded))
		{
			loading(true);
			fetch("http://the10wishes.herokuapp.com/api/v1/comments/"+postId,{'headers':{'authorization':'Bearer '+state.auth.token}})
		      .then(res => res.json())
		      .then(
		        (result) => {
		            loadComments(postId,result['data']);
		            loading(false);
		        },
		        (error) => {
		          console.log('Errore nel caricamento dei post')
		          loading(false);
		        }
		      )
		}
	}
	function sendFriendRequest(userid){
		var xhr = formatXhr('GET', 'http://the10wishes.herokuapp.com/api/v1/users/request/'+userid);
		xhr.send();
	}
	function addUser(user,formData){
		console.log(formData);
		user._id = uuid.v4();
		user.id = user._id;
		user.checked = false;
		user.published = true;
		user.dateUpdated = new Date();
		user.dateCreated = new Date();
		formData.append('_id',user._id );
		formData.append('id',user.id );
		formData.append('dateUpdated',user.dateUpdated );
		formData.append('dateCreated',user.dateCreated );
		formData.append('company','bucketlist');
		var xhr = formatXhr('POST', 'http://the10wishes.herokuapp.com/api/v1/users/');
		xhr.send(formData);
		setTimeout(function() {
			dispatch({
				type:"ADD_USER",
				payload:user
			});
		}, 2000);
	}
	function loadPosts(posts){
		dispatch({
			type:"LOAD_POSTS",
			payload:posts
		});
	}
	function loadFeed(feed){
		dispatch({
			type:"LOAD_FEED",
			payload:feed
		});
	}
	function loadComments(postId,comments){
		dispatch({
			type:"LOAD_COMMENTS",
			payload:{postId,comments}
		});
	}
	function setPostLoaded(bool){
		dispatch({
			type:"SET_LOADED",
			payload:bool
		});
	}
	function setFeedLoaded(bool){
		dispatch({
			type:"FEED_LOADED",
			payload:bool
		});
	}
	function logout(){
		loading(true);
		dispatch({
			type:"LOGOUT"
		});
		document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
		document.cookie = 'me=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
		history.push('/login');
		loading(false);
	}
	function loading(bool){
		dispatch({
			type:"LOADING",
			payload:bool
		});
	}
	function authenticate(user){
		loading(true);
		var xhr = new XMLHttpRequest();
		xhr.open('POST', 'http://the10wishes.herokuapp.com/api/v1/login');
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onload = function(e) {
			loading(false);
			try {
		       var auth = JSON.parse(this.response);
		       state.auth = auth;
		       document.cookie = 'token='+auth['token']+';path=/;';
		       document.cookie = 'me='+JSON.stringify(auth['user'])+';path=/;';
		       history.push('/');
		    } catch(e) {
		        alert('Login fallito, credenziali errate');
		    }	
		};
		xhr.send(JSON.stringify(user));
	}
	function formatXhr(method,url){
		var xhr = new XMLHttpRequest();
		xhr.onload = function(e) {
			loading(false);
		};
		xhr.open(method, url);
		if(typeof state.auth.token!='undefined')
			xhr.setRequestHeader("authorization", "Bearer "+state.auth.token);
		return xhr;
	}
	function showFeed(){
		history.push('/feed');
		dispatch({
			type:"SHOW_FEED"
		});
	}
	function getUserInfo(userid){
		if(typeof state.loadedUsers[userid]!='undefined')
			return state.loadedUsers[userid];
		else{
			fetchUser(userid);
			return null;
		} 
	}
	function getProfilePicture(user){
		var imgsrc='https://picsum.photos/768';
		if(typeof user['avatar']!='undefined' && user['avatar']){
		     var avatar =  user['avatar'];
		    if(avatar.includes('http')){
		      imgsrc = avatar;
		    }else{
		      imgsrc = "http://the10wishes.herokuapp.com/uploads/"+user['_id']+""+avatar.substr(avatar.lastIndexOf('.'));
		    }
		}
		return imgsrc;
	}
	function showHome(){
		history.push('/');
		dispatch({
			type:"SHOW_HOME"
		});
	}
	return (<GlobalContext.Provider value={{feed_loaded:state.feed_loaded,feed:state.feed, title:state.title,friends:state.friends, friendsRequests:state.friendsRequests, userQuery:state.userQuery,userSearch:state.userSearch,is_loading:state.is_loading,home:state.home,auth:state.auth,loaded:state.loaded,posts:state.posts,deletePost,archivePost,checkPost,addPost,loadPosts,setPostLoaded,addUser,authenticate,logout,isLogged,loading,addComment,getCommentsByPostId,fetchComments,searchUser,sendFriendRequest,loadFriends,befriend,showFeed,showHome,getFeed,getUserInfo,getProfilePicture,addCommentToFriendFeed}}>{children}</GlobalContext.Provider>);
}