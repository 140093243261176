import React,{Component,useContext,useState}   from 'react';
import {GlobalContext}                from '../context/GlobalState';
import PersonAddOutline               from 'react-ionicons/lib/MdPersonAdd';
import PrintOutline                   from 'react-ionicons/lib/MdPrint';
import ExitOutline                    from 'react-ionicons/lib/MdExit';
import ArrowForwardOutline            from 'react-ionicons/lib/MdArrowForward';
import CheckboxOutline                from 'react-ionicons/lib/MdCheckbox';
import './FriendSearch.css';
import './User.css';
const User = (params) =>{
  const context = useContext(GlobalContext);
  const [display,setDisplay]  = useState(true);
  const show = display ? 'show' : 'hidden';
  const addFriend = (id)=>{
    context.sendFriendRequest(id);
    setDisplay(false);
  }
  const showUser=()=>{

  }
  const befriend=(id)=>{
     context.befriend(id);
     setDisplay(false);
  }
  const Action = (params)=>{
    const type = typeof params['type']!='undefined'? params['type'] : 'add';
    switch (type) {
      case 'add':
       return(<button className="plus" onClick={e=>addFriend(params['user']['_id'])}><PersonAddOutline></PersonAddOutline></button>)
      break;
      case 'befriend':
         return(<button className="plus" onClick={e=>befriend(params['user']['_id'])}><CheckboxOutline></CheckboxOutline></button>)
      break;
      case 'show':
         return(<button className="plus" onClick={e=>showUser(params['user']['_id'])}><ArrowForwardOutline></ArrowForwardOutline></button>)
      break;
    }
  }
  var imgsrc='https://picsum.photos/768';
  if(typeof params['user']['avatar']!='undefined' && params['user']['avatar']){
     var avatar =  params['user']['avatar'];
    if(avatar.includes('http')){
      imgsrc = avatar;
    }else{
      imgsrc = "http://the10wishes.herokuapp.com/uploads/"+params['user']['_id']+""+avatar.substr(avatar.lastIndexOf('.'));
    }
  }
  var style = {'backgroundImage':"url("+imgsrc+")"};
  return( <div className={'user-container '+show}><div className="img-container" style={style}></div><div className="user-info">{params['user']['name']} {params['user']['surname']}</div> <Action user={params['user']} type={params['type']}/> </div>);
 }
const FriendSearch = (params) =>{
  const context = useContext(GlobalContext);
  const searchPeople = (search)=>{
    context.searchUser(search);
  }
  const users = typeof context.userSearch[context.userQuery]!='undefined' ? context.userSearch[context.userQuery] : [];
  const show = params['show'] ? 'active' : '';
  return (
      <div className={'search-friend-input-container '+show}>
        <input type="search" onInput={e=>{searchPeople(e.target.value)}} className="search-friend-input"/>
        <div className="search-results">{users.map((user, order) => (<User key={user.id} user={user} order={order} />))}</div>
      </div>);
}
const FriendRequests = (params) =>{
  const context = useContext(GlobalContext);
  const users = typeof context.friendsRequests!='undefined' && context.friendsRequests ? Object.values(context.friendsRequests) : [];
  const show = users.length>0 ? 'show':'hidden';
  return (
           <div className={'request  '+show}>Richieste di Amicizia <div className="search-results">{users.map((user, order) => (<User key={user.id} user={user} order={order} type='befriend' />))}</div></div>
         );
}
const ActualFriends = (params) =>{
  const context = useContext(GlobalContext);
  const users = typeof context.friends!='undefined' && context.friends ? Object.values(context.friends) : [];
  const show = users.length>0 ? 'show':'hidden';
  return (
           <div className={'request  '+show}>Amici <div className="search-results">{users.map((user, order) => (<User key={user.id} user={user} order={order} type='show' />))}</div></div>
         );
}
const Friends = (params) =>{
  const show = params['show'] ? 'active' : '';
  return (
      <div className={'search-friend-input-container  '+show}>
         <FriendRequests/>
         <ActualFriends/> 
      </div>);
}
const LoggedNavbar = ()=>{
  const context = useContext(GlobalContext);
  const [showSearch,setShowSearch]    = useState(false);
  const [showFriends,setShowFriends]  = useState(false);
  const out = () =>{
    context.logout();
  }
  const loadFriends = ()=>{
    context.loadFriends();
  }
  const showFeed = ()=>{
    context.showFeed();
  }
  if(context.isLogged()){
    return (<div className="collapsible-body">
                  <ul className="inline">
                    <li><a href="#" onClick={e=>showFeed()}>Feed</a></li>
                    <li><a href="#" onClick={e=>{loadFriends(); setShowFriends(!showFriends)}}>Amici</a><Friends show={showFriends}/></li>
                    <li><a href="#" onClick={e=>{setShowSearch(!showSearch)}}><PersonAddOutline></PersonAddOutline></a><FriendSearch show={showSearch}/></li>
                    <li><a href="#" onClick={e=>{window.print()}}><PrintOutline></PrintOutline></a></li>
                    <li><a onClick={e=>{out()}} href="#"><ExitOutline></ExitOutline></a></li>
                  </ul>
            </div>);
  }
  else{
    return (<div className="collapsible-body"></div>)
  }
}
const Home = ()=>{
       const showHome = ()=>{
        context.showHome();
      }
      const context = useContext(GlobalContext);
      return(<div className="nav-brand">
                <h4><a onClick={e=>showHome()} href="#">Home</a></h4>
              </div>);
}

export const NavBar = ()=>{
  return (<nav className="border fixed">
              <Home/>
              <div className="collapsible">
                <input id="collapsible2" type="checkbox" name="collapsible2"/>
                <button>
                  <label htmlFor="collapsible2">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                  </label>
                </button>
                <LoggedNavbar/>
              </div>
            </nav>);
}