export default(state,action)=>{
	switch(action.type){
		case 'DELETE_POST':
			return{
				...state,
				posts:state.posts.filter(post=>post.id!==action.payload)
			}
		case 'ARCHIVE_POST':
			return{
					...state,
					posts:state.posts.filter(post=>post.id!==action.payload)
				}
		case 'CHECK_POST':
			return{
					...state,
					posts:state.posts.map((post, key)=>{
						if(post.id==action.payload)
							post.checked=!post.checked;
						return post;
					})
				}
		case 'ADD_POST':
				return{
					...state,
					posts:[action.payload].concat(state.posts.concat())
				}
		case 'LOAD_POSTS':
		        var id_converted = {};
		        action.payload.map((post, key)=>{
		        	id_converted[post['_id']] = post;
 		        });
				return{
					...state,
					loadedPosts:{...state.loadedPosts,...id_converted},
					posts:state.posts.concat(action.payload)
				}
		case 'FEED_LOADED':
				return{
					...state,
					feed_loaded:action.payload
				}
		case 'LOAD_FEED':
				var id_converted = {};
		        action.payload.map((post, key)=>{
		        	id_converted[post['_id']] = post;
 		        });
				return{
					...state,
					loadedPosts:{...state.loadedPosts,...id_converted},
					feed:state.feed.concat(action.payload)
				}
		case 'LOAD_COMMENTS':
				var loaded = state.loadedPosts;
				var post = loaded[action.payload.postId]
				if(typeof post.comments=='undefined')
					post.comments=[];
				post.comments= action.payload.comments;
				post.loaded = true;
				loaded[action.payload.postId] = post;
				return{
					...state,
					loadedPosts:loaded
				}
		case 'ADD_COMMENT':
				var loaded = state.loadedPosts;
				var post = loaded[action.payload.postId];
				if(typeof post.comments=='undefined')
								 post.comments=[];
				post.comments.push(action.payload);
				loaded[action.payload.postId] = post;
				return{
					...state,
					loadedPosts:loaded
				}
		case 'FRIENDS_FETCHED':
			return{
				...state,
				friends:action.payload,
				loadedUsers:{...state.loadedUsers,...action.payload},
				friends_loaded:true
			}
		case 'PENDING_REQUESTS_FETCHED':
			return{
				...state,
				friendsRequests:action.payload,
				loadedUsers:{...state.loadedUsers,...action.payload},
				pending_loaded:true
			}
		case 'SEARCH_USER_COMPLETED':
				let s = state.userSearch;
				s[action.payload.search] = action.payload.result;
				return{
					...state,
					userSearch:s,
					userQuery:action.payload.search
				}
		case 'LOADING':
				return{
					...state,
					is_loading:action.payload
				}
		case 'SET_LOADED':
				return{
					...state,
					loaded:action.payload
				}
		case 'USER_INFO_FETCHED':
				loaded = state.loadedUsers;
				loaded[action.payload._id] = action.payload;
				return{
					...state,
					loaded
				}
		case 'LOGOUT':
				return{
					    ...state,
						loaded:false,
						home:'/projects/websites/bucket_list',
						is_loading:false,
						cookies:{},
						auth:{},
						posts:[
							/*{'id':1, _id:1, 'checked':true,  'published':true, 'title':'Trip to australia','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/qaNcz43MeY8','dateUpdated':new Date(),'dateCreated':new Date()},
							{'id':2, _id:2, 'checked':false, 'published':true, 'title':'Rent a Lambo','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/1_lxISUE3F4','dateUpdated':new Date(),'dateCreated':new Date()},
							{'id':3, _id:3, 'checked':true,  'published':true, 'title':'Plant trees','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/sMQiL_2v4vs','dateUpdated':new Date(),'dateCreated':new Date()},
							{'id':4, _id:4, 'checked':true,  'published':true, 'title':'Hold a cat','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/LEpfefQf4rU','dateUpdated':new Date(),'dateCreated':new Date()},
							{'id':5, _id:5,'checked':true,   'published':true, 'title':'Attend a rodeo','description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.','cover':'https://source.unsplash.com/nGZsjDn_s6U','dateUpdated':new Date(),'dateCreated':new Date()}
						*/
						],
						userQuery:'',
						userSearch:{},
						pending_loaded:false,
						friends_loaded:false,
						friends:{},
						friendsRequests:{}
				}
		case 'SHOW_FEED':{
			return{
				...state,
				title:'Ai miei amici piacerebbe...'
			}
		}
		case 'SHOW_HOME':{
			return{
				...state,
				title:'Vorrei tanto...'
			}
		}
		default:
			return state;
	}
}