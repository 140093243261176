import React,{Component,useContext,useState} from 'react';
import './Channel.css';
import './CommentBox.css';
import './Comment.css';
import {GlobalContext} from '../../context/GlobalState';
import { useHistory } from "react-router-dom";
import TrashOutline from 'react-ionicons/lib/MdTrash';
import ArchiveOutline from 'react-ionicons/lib/MdArchive';
const Comment = (params)=>{
  var context =  useContext(GlobalContext);
  var user = context.getUserInfo(params['comment']['user']['_id']);
  var imgsrc = context.getProfilePicture(user);
  var style = {'backgroundImage':"url("+imgsrc+")"};
return (<div class="comment-main-container">
    <div class="comment-picture-main-container">
        <button type="button" class="comment-picture-container" style={style}>
            {/*<img src={imgsrc}/>*/}
        </button>
        <div className="comment-person-name">{user['name']} {user['surname']}</div>
    </div>
    <div class="comment-text-main-container">
        <div class="comment-text fade">{params['comment']['text']}</div>
    </div>
</div>);
}
const CommentList = (params) =>{
  const {getCommentsByPostId} =  useContext(GlobalContext);
  let empty = false;
  let postId   = null;
  let comments = null;
  if(params['postId']!='undefined'){
    postId   = params['postId'];
  }else{
    empty=true;
  }
  if(!empty){
    comments = getCommentsByPostId(postId);
  }
  if(!comments){
    empty=true;
  }
  if(empty){
    return(<div></div>);
  }
  else{
    return (<div>{comments.map((comment, order) => (<Comment key={comment.id} comment={comment} order={order} />))}</div>);
  }
}
const CommentBox = (params)=>{
  const show = params['show'] ? 'show' : 'hidden';
  const postId = params['postId'];
  const context = useContext(GlobalContext);
  const [comment,setComment] = useState('');
  const addComment = (e) =>{
    e.preventDefault();
    const newComment = {postId,'text':comment};
    if(params['from']=='feed'){
      context.addCommentToFriendFeed(newComment);
    }
    else{
      context.addComment(newComment);
    }
  }
  return (<form onSubmit={addComment}>
          <div className={`comment-box-container ${show}`}>
            <div className="flex">
              <div className="text-container">
                <input className="input-block" type="text" value={comment}  onChange={(e)=>setComment(e.target.value)}/>
              </div>
              <div className="button-container">
                <button>Invia</button>
              </div>
            </div>
           <CommentList postId={postId}/>
          </div></form>);
}
export const Post = ({post,order,_from}) =>{  
     const [show_comment_box,showCommentBox] = useState(false);
     const {deletePost,archivePost,checkPost,fetchComments,getUserInfo,getProfilePicture} = useContext(GlobalContext);
     const checkItem = (e) =>{
      var ch =e.target;
      let checkboxCL = ch.classList, parentCL = ch.parentElement.classList; 
      checkboxCL.toggle('pristine');
      parentCL.toggle('done');
      checkPost(ch.getAttribute('data-post'));
    }
    const getContainerClass= (checked)=>{
     return checked ? 'done':'';
    }
    const getInputClass = (checked)=>{
      return checked ? '':'pristine';
    }
    const getCoverStyle = (post,order)=>{
      console.log( "mymove 15s linear infinite "+order+"s");
      if(typeof post.cover!='undefined' && post.cover){
        var url = '';
        if(post.cover.includes('http')){
          url = post.cover;
        }else{
          url = "http://the10wishes.herokuapp.com/uploads/"+post._id+""+post.cover.substr(post.cover.lastIndexOf('.'));
        }
        return {backgroundImage: "url("+url+")",  animation: "mymove 15s linear 1",'animationDelay':parseInt(order)*2+"s"}
      }
      else{
        return {backgroundImage: "none",height:"0px"};
      }
    } 
    const getCheckbox = ()=>{
      if(_from!='feed')
        return(<input data-post={post._id} className={'cb '+getInputClass(post.checked)} type="checkbox" defaultChecked={post.checked} onClick={checkItem}/>);
      else
         return(<input data-post={post._id} readOnly='readonly'   disabled="disabled" className={'cb '+getInputClass(post.checked)} type="checkbox" defaultChecked={post.checked} onClick={checkItem}/>);
    }
    let user = {};
    if(_from=='feed'){
      user = getUserInfo(post['user']['_id']);
    }
    return(<div className="card">
            <div className="myClass" style={getCoverStyle(post,order)}/>
            <div className="card-body">
             <div className={getContainerClass(post.checked)}>{getCheckbox()}<h4 className="card-title custom">{post.title}</h4></div>
             <p className="card-text custom">{post.description}</p>
             <div className="post-button-container"> 
              <div><button>Mostra</button></div>
              <div><button onClick={(e)=>{ fetchComments(post._id); showCommentBox(!show_comment_box)} }>Commenti</button></div>
              <div><button>Anch'io!</button></div>
              {_from!='feed' && (
                <div><button  onClick={()=>archivePost(post._id)}><ArchiveOutline /></button></div>
              )}             
              {_from!='feed' && (
               <div><button onClick={()=>deletePost(post._id)}><TrashOutline /></button></div>
              )}
             </div> 
              {_from=='feed'&& user && (
               <div><div className={'post-user-container '+(show_comment_box?'hidden':'')}>
                   <button className='post-user-icon' style={{'backgroundImage':"url("+getProfilePicture(user)+")"}}>
                   </button>
                   <br/>
                   <div className='post-user-text'>
                   {user['name']} {user['surname']}
                   </div>
               </div></div>
              )} 
              
             <CommentBox  show={show_comment_box} postId={post._id} from={_from} />
            </div>
          </div>);
}
class Channel extends Component{
  render(){
      return (
         <Post post={this.props.post} order={this.props.order} _from={this.props.from} />
      )
  }
}
export default Channel;