import React,{Component,useContext}   from 'react';
import Channels                       from './components/Channels/Channels.jsx';
import {AppLoader}                    from './context/Loader.jsx';
import Toolbar                        from './components/Controls/Toolbar.jsx';
import NotFound                       from './components/Utils/NotFound.jsx';
import uuid                           from 'uuid';
import Login                          from './components/Login/Login.jsx';
import {NavBar}                         from './components/Navbar.jsx';
import {Loading}                      from './components/Utils/Loading.jsx';
import './App.css';
import 'papercss/dist/paper.min.css';
import {GlobalProvider,GlobalContext} from './context/GlobalState';
import { PrivateRoute,LoginRoute } from './components/Login/PrivateRoute.jsx';
import Background from "./assets/img/119.jpg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
}                                     from "react-router-dom";
import { withRouter }                 from "react-router";
import { useRouteMatch }              from "react-router-dom";
const Title = () =>{
  var context = useContext(GlobalContext);
  return(<div className="Main-Title-Container">
                <h1 className="Main-Title">{context.title}</h1>
            </div>);
}
class App extends Component{
  render(){
      const style = "#root{ background: linear-gradient( rgba(255,235,205,0.2), blanchedalmond ),url("+Background+"); background-size: cover; }";
      return (   
         <GlobalProvider>
         <AppLoader app={this} />
          <div className="App">
          <style dangerouslySetInnerHTML={{__html: style}} />
            <Loading/>
            <NavBar/>
            <Title/>
            <Toolbar/>
            <LoginRoute component={Login}  exact path="/login"/>
            <PrivateRoute component={Channels} exact path="/"/>
            <PrivateRoute component={Channels} params={{'type':'feed'}} exact path="/feed"/>
            <Route exact path="/404" component={NotFound} />
          </div>
          </GlobalProvider>
      )
  }
}
export default withRouter(App);