import React,{useContext} from 'react';
import {GlobalContext} from '../../context/GlobalState';
import './Loading.css';
export const Loading = ()=>{
	//var props = params['props'];
	const {is_loading}  = useContext(GlobalContext);
	const show = is_loading ? 'show' : 'hidden';
	return (<div className={`loading ${show}`}>
      <div className='uil-ring-css'>
        <div></div>
      </div>
    </div>);
}
