import React,{Component,useState, useContext, useRef} from 'react';
import {GlobalContext} from '../../context/GlobalState';
import './Login.css';
import './MultipleForm.css';
const MultipleForm  = () =>{
  const main_container = useRef(null);
  const [image,setImage]         = useState('');
  const [avatar,setAvatar]       = useState('');
  const [name,setName]           = useState('');
  const [surname,setSurname]     = useState('');
  const [email,setEmail]         = useState('');
  const [password,setPassword]   = useState('');
  const [quote,setQuote]         = useState('');
  const {addUser,authenticate}          = useContext(GlobalContext);
  const onImageSelect =(e)=>{
     setImage(e.target.files[0]);
     setAvatar(e.target.files[0].name);
  }
  const signUp = (e)=>{
    e.preventDefault();
    const newUser = {avatar,name,surname,email,password,quote};
    console.log(e.target);
    const formData = new FormData();
    formData.append('file', image);
    formData.append('avatar', avatar);
    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('quote', quote);
    addUser(newUser,formData);
  };

  const login = (e)=>{
    e.preventDefault();
    const loginUser = {email,password};
    authenticate(loginUser);
  }

return (<div id="multiple-form" className="main-container">
<div>
<div ref={main_container}  className="container" id="container">
  <div className="form-container sign-up-container">
    <form onSubmit={signUp}>
      <h2 className="no-margin">Registrazione</h2>
      <div className="social-container">
        <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
        <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
        <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
      </div>
      <span>or use your email for registration</span>
      <input type="file"   onChange={(e)=>onImageSelect(e)} placeholder="Avatar"/>
      <input type="text"   onChange={(e)=>setName(e.target.value)} placeholder="Nome"/>
      <input type="text"   onChange={(e)=>setSurname(e.target.value)} placeholder="Cognome"/>
      <input type="text"   onChange={(e)=>setQuote(e.target.value)} placeholder="My Inspirational Quote"/>
      <input type="text"   onChange={(e)=>setEmail(e.target.value)} placeholder="E-Mail"/>
      <input  id="user-password" name="password"  type="password" onChange={(e)=>setPassword(e.target.value)}  placeholder="Password"/>                 
      <button className="paper-btn margin" type="submit">Registrati</button>
      <button type="button" className="paper-btn no-margin" onClick={(e)=>main_container.current.classList.remove("right-panel-active")}>Accedi</button>
    </form>
  </div>
  <div className="form-container sign-in-container">
    <form onSubmit={login}>
      <h2 className="no-margin">Accedi</h2>
      <div className="social-container">
        <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
        <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
        <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
      </div>
      <span>or use your account</span>
      <input type="text"    onChange={(e)=>setEmail(e.target.value)}   placeholder="Email" />
      <input type="password" onChange={(e)=>setPassword(e.target.value)} placeholder="Password" />
      <a href="#">Forgot your password?</a>
      <button className="paper-btn margin">Accedi</button>
       <button className="paper-btn no-margin mobile" onClick={(e)=>main_container.current.classList.add("right-panel-active")}>Registrati</button>
    </form>
  </div>
  <div className="overlay-container">
    <div className="overlay">
      <div className="overlay-panel overlay-left">
        <h2>Bentornato!</h2>
        <p>Accedi per vedere le tue fantasie</p>
        <button type="submit" className="paper-btn margin" id="signIn" onClick={(e)=>main_container.current.classList.remove("right-panel-active")}>Accedi</button>
      </div>
      <div className="overlay-panel overlay-right">
        <h2>Ciao!</h2>
        <p>Crea il tuo profilo e Inizia a sognare</p>
        <button type="button" className="paper-btn margin" id="signUp" onClick={(e)=>main_container.current.classList.add("right-panel-active")}>Registrati</button>
      </div>
    </div>
  </div>
</div>
</div>
</div>);
}

class Login extends Component{
  constructor(props){
    super(props);
    this.state = {
      visible:props.visible
    };
  }
  render(){
     return (<div className='login-container'>
                <h2>Collegati per Continuare</h2>
                <MultipleForm />
              </div>);
  }
}
export default Login;