import React,{Component,useState, useContext} from 'react';
import {GlobalContext} from '../../context/GlobalState'
import Channel from './Channel.jsx';
import PropTypes from 'prop-types';
import './Channels.css';
const PostForm = () =>{
  const [cover,setCover]             = useState('');
  const [image,setImage]             = useState('');
  const [title,setTitle]             = useState('');
  const [description,setDescription] = useState('');
  const {addPost} =  useContext(GlobalContext);
  const onImageSelect =(e)=>{
     setImage(e.target.files[0]);
     setCover(e.target.files[0].name);
  }
  const submit = (e)=>{
    e.preventDefault();
    const newPost = {cover,title,description};
    console.log(e.target);
    const formData = new FormData();
    formData.append('file', image);
    formData.append('cover', cover);
    formData.append('title', title);
    formData.append('description', description);
    addPost(newPost,formData);
  };
  return ( <form id="form-aggiungi-post" onSubmit={submit} className="form-upload-post">
                <div className="form-group">
                  <div className="input-container">
                    <label htmlFor="post-cover">Copertina</label>
                    <input type="file" id="post-cover"    onChange={(e)=>onImageSelect(e)} placeholder="Carica un immagine"/>
                  </div>
                  <div className="input-container">
                    <label htmlFor="post-title">Titolo</label>
                    <input type="text" id="post-title"  value={title}     onChange={(e)=>setTitle(e.target.value)} placeholder="Vorrei ..."/>
                  </div>
                  <div className="input-container">
                    <label htmlFor="post-description">Descrizione</label>
                    <textarea  id="post-description" value={description} onChange={(e)=>setDescription(e.target.value)}  placeholder="Large input"></textarea>
                  </div>
                   <button>Aggiungi</button>
                </div>
              </form>);
}
const PostList = ({getFrom}) =>{
  const context =  useContext(GlobalContext);
  console.log('get from');
  console.log(getFrom);
  getFrom = getFrom();
  const posts   = context[getFrom];
  return (<div>{posts.map((post, order) => (<Channel key={post.id} from={getFrom} post={post} order={order} />))}</div>);
}

const Loader = ({type})=> {
   const context =  useContext(GlobalContext);
  if(type()=='feed' && !context.feed_loaded){
     context.showFeed();
     context.getFeed();
     console.log('ciao');
  }
  return(<div></div>);
}
class Channels extends Component{
  render(){
     const Form = ()=>{
        const type = typeof this.props.type!='undefined' ? this.props.type : 'mybucketlist';
        const showPostForm = type=='mybucketlist';
       if(showPostForm)
         return (<PostForm />);
       else 
         return (<div></div>);
     }
     const getFrom = ()=>{
        const type = typeof this.props.type!='undefined' ? this.props.type : 'posts';
        return type;
     }
     return (<div className="Channels">
                <Form/>
                <Loader type={getFrom}/>
                <PostList getFrom={getFrom}></PostList>
              </div>);
  }
}
export default Channels;