import React,{Component} from 'react';
class NotFound extends Component{
 render(){
      return (
        <div className="NotFound">
          404 - Not Found
        </div>
      )
  }
}
export default NotFound;