import React,{useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {GlobalContext} from '../../context/GlobalState';
import {authenticationService} from '../../services/authentication.service.js';
const RedirectLogin= ({props,component: Component})=>{
	var context = useContext(GlobalContext);
	if (context.isLogged()) {
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }else{
    	  return <Component {...props} />
    }
}
const RedirectPrivate= ({props,component: Component})=>{
	var context = useContext(GlobalContext);
  	if (!context.isLogged()) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }else{
    	// authorised so return component
        return <Component {...props} />
    }
}
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        if(typeof rest['params']=='undefined'){
            rest['params'] = {};
        }
      return <RedirectPrivate props={{...rest['params'],...props}} component={Component}/>
    }} />
)
export const LoginRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
    	 return <RedirectLogin props={props} component={Component} />
    }} />
)